import React from 'react';

const Footer = () => {
  return (
    <div className="text-center text-gray-500 text-xs py-4 font-serif">
      <span className="opacity-70 hover:opacity-100 transition-opacity duration-300">
        Built by Aelionyx
      </span>
    </div>
  );
};

export default Footer; 